


























































































































































































































































































































































































































































































































































































import { Component, Vue, Watch } from 'vue-property-decorator'
import TextInput from '@/app/ui/components/TextInput/index.vue'
import DataTableV2 from '@/app/ui/components/DataTableV2/index.vue'
import AscendingIcon from '@/app/ui/assets/ascending_icon.vue'
import Loading from '@/app/ui/components/Loading/index.vue'
import { Utils } from '@/app/infrastructures/misc'
import controller from '@/app/ui/controllers/PickupController'
import dayjs from 'dayjs'
import dayjsDuration from 'dayjs/plugin/duration'
import {
  Pickup,
  PickupCRROTWs,
  PickupCRROTWSummary,
} from '@/domain/entities/Pickup'
import PaginationNav from '@/app/ui/components/PaginationNav/index.vue'
import {
  IOptions,
  IHeaderCell,
  ITableCell,
  Parameters,
} from '@/data/infrastructures/misc/interfaces/pickupCRROTW'
import {
  SortFieldsCRROTW,
  SortTypeCRROTW,
} from '@/app/infrastructures/misc/Constants/pickup'
import DropdownSelect from '@/app/ui/components/DropdownSelect/index.vue'
import Button from '@/app/ui/components/Button/index.vue'
import IconSync from '@/app/ui/assets/icon_sync.vue'
import IconWhatsApp from '@/app/ui/assets/ics_f_whatsapp.vue'
import LoadingOverlay from '@/app/ui/components/LoadingOverlay/index.vue'
import IconWarningCircle from '@/app/ui/assets/ics_f_warning_circle.vue'
import CourierCallCheckbox from '../components/CourierCallCheckbox/index.vue'
import { Pagination } from '@/domain/entities/Pagination'
import ModalForceCRRCNC from '../components/Modals/ModalForceCRRCNC.vue'
import ModalSuccess from '../components/Modals/ModalSuccess.vue'
import DropdownMultiSelect from '../components/DropdownMultiSelect/index.vue'

dayjs.extend(dayjsDuration)

@Component({
  components: {
    TextInput,
    DataTableV2,
    AscendingIcon,
    Loading,
    PaginationNav,
    DropdownSelect,
    Button,
    IconSync,
    IconWhatsApp,
    LoadingOverlay,
    IconWarningCircle,
    CourierCallCheckbox,
    ModalForceCRRCNC,
    ModalSuccess,
    DropdownMultiSelect,
  },
})
export default class PickupCRROTWPage extends Vue {
  controller = controller

  enumSelectedSort = SortTypeCRROTW
  enumSortFields = SortFieldsCRROTW

  headers = [
    this.headerCellMapper('No.', '60px'),
    this.headerCellMapper('Order ID', '160px'),
    this.headerCellMapper('Nama Customer', '200px'),
    this.headerCellMapper('No. Handphone', '190px'),
    this.headerCellMapper('Status', '140px'),
    this.headerCellMapper('Alamat', '260px'),
    this.headerCellMapper('Nama Kurir', '200px'),
    this.headerCellMapper('Durasi Pick Up (CRROTW)', '200px'),
    this.headerCellMapper('Terakhir Diperbaharui', '176px'),
    this.headerCellMapper('Durasi Pick Up (SHPCRT)', '200px'),
    this.headerCellMapper('Durasi Pick Up (First CRRSRC)', '200px'),
    this.headerCellMapper('Atur', '183px'),
  ]

  selectedSort: SortTypeCRROTW | null = null
  parameters: Parameters = {
    page: 1,
    perPage: 10,
    search: '',
    shipmentType: [],
    pickupDuration: {
      label: 'Semua',
      value: 'all',
    },
    courierCall: {
      label: 'Semua',
      value: 'all',
    },
  }

  shipmentTypeOptions = [
    {
      label: 'Delivery Order (DO)',
      value: 'delivery_order',
    },
    {
      label: 'Marketplace (MP)',
      value: 'marketplace',
    },
    {
      label: 'Customer Apps (CA)',
      value: 'customer',
    },
    {
      label: 'Corporate',
      value: 'corporate',
    },
    {
      label: 'COD Balikan',
      value: 'cod_return'
    }
  ]

  pickupDurationOptions = [
    {
      label: 'Semua',
      value: 'all',
    },
    {
      label: '0 - 30',
      value: '30',
    },
    {
      label: '31 - 60',
      value: '60',
    },
    {
      label: '61 - 90',
      value: '90',
    },
    {
      label: '91 - 120',
      value: '120',
    },
    {
      label: '121 - 150',
      value: '150',
    },
    {
      label: '151 - 180',
      value: '180',
    },
    {
      label: '181 - 210',
      value: '210',
    },
    {
      label: '210+',
      value: '210+',
    },
  ]

  courierCallOptions = [
    {
      label: 'Semua',
      value: 'all',
    },
    {
      label: 'Sudah Hubungi Kurir',
      value: 'contacted',
    },
    {
      label: 'Belum Hubungi Kurir',
      value: 'can_contact',
    },
  ]

  tableData: ITableCell[][] = []

  perPageOptions: IOptions[] = [
    { label: '10', value: 10 },
    { label: '50', value: 50 },
    { label: '100', value: 100 },
  ]

  summary: PickupCRROTWSummary = {
    thirty: 0,
    sixty: 0,
    ninety: 0,
    onetwenty: 0,
    onefifty: 0,
    oneeighty: 0,
    twoten: 0,
    moretwoten: 0,
  }

  pagination = new Pagination()

  showForceCRRCNCModal = false
  forceCRRCNCShipmentId = ''
  showSuccessForceCRRCNCModal = false

  created(): void {
    this.fetchPickups(true, true)
  }

  get params(): Record<string, string | number | boolean> {
    return {
      page: this.parameters.page,
      perPage: this.parameters.perPage,
      keyword: this.parameters.search,
      dateFrom: this.dateFrom(),
      syncSummary: false,
      shipmentType: <string>(
        this.parameters.shipmentType
          ?.map(
            type =>
              this.shipmentTypeOptions.find(option => option.label === type)
                ?.value
          )
          .join(',')
      ),
      pickupDuration: encodeURIComponent(
        <string>this.parameters.pickupDuration?.value
      ),
      courierCall: <string>this.parameters.courierCall?.value,
      sortDirection: this.selectedSort ? this.selectedSort.split('-')[1] : '',
      column: this.selectedSort ? this.selectedSort.split('-')[0] : '',
    }
  }

  get minDate(): Date {
    return new Date(new Date().setDate(new Date().getDate() - 7))
  }

  get isexportPickupListLoading(): boolean {
    return controller.isExportCRROTWLoading
  }

  get pickupData(): Pickup[] {
    return controller.pickupData
  }

  get statusId(): string {
    return <string>this.$route.meta.additional.statusId
  }

  get isLoading(): boolean {
    return controller.isLoading
  }

  get latestSync(): string {
    if (!controller.latestSync) {
      return ''
    }

    return dayjs(controller.latestSync).format('DD-MM-YY HH:mm')
  }

  public onFilterShipmentType(value: string[]): void {
    this.parameters.shipmentType = value
    this.fetchPickups(true)
  }

  public onFilterPickupDuration(value: { label: string; value: string }): void {
    if (!value) {
      this.parameters.pickupDuration = {
        label: 'Semua',
        value: 'all',
      }
    }
    this.fetchPickups(true)
  }

  public onFilterCourierCall(value: { label: string; value: string }): void {
    if (!value) {
      this.parameters.courierCall = {
        label: 'Semua',
        value: 'all',
      }
    }
    this.fetchPickups(true)
  }

  public dateFrom(): string {
    return dayjs()
      .subtract(1, 'week')
      .format('YYYY-MM-DD')
  }

  private fetchPickups(reset: boolean, sync = false): void {
    if (reset) this.parameters.page = 1

    const payload = { ...this.params }

    payload.syncSummary = sync

    if (!this.parameters.search) {
      delete payload.keyword
    }

    controller.getPickupCRROTW(payload)
  }

  public fetchPickupsPerPage(perPage: number): void {
    this.parameters.perPage = perPage
    this.fetchPickups(true)
  }

  public onTableSort(sortType: SortFieldsCRROTW): void {
    switch (sortType) {
      case SortFieldsCRROTW.ORDER_ID:
        if (this.selectedSort === SortTypeCRROTW.ORDER_ID_ASC) {
          this.selectedSort = SortTypeCRROTW.ORDER_ID_DESC
        } else if (this.selectedSort === SortTypeCRROTW.ORDER_ID_DESC) {
          this.selectedSort = null
        } else {
          this.selectedSort = SortTypeCRROTW.ORDER_ID_ASC
        }
        break

      case SortFieldsCRROTW.CUSTOMER_NAME:
        if (this.selectedSort === SortTypeCRROTW.CUSTOMER_NAME_ASC) {
          this.selectedSort = SortTypeCRROTW.CUSTOMER_NAME_DESC
        } else if (this.selectedSort === SortTypeCRROTW.CUSTOMER_NAME_DESC) {
          this.selectedSort = null
        } else {
          this.selectedSort = SortTypeCRROTW.CUSTOMER_NAME_ASC
        }
        break

      case SortFieldsCRROTW.PHONE_NUMBER:
        if (this.selectedSort === SortTypeCRROTW.PHONE_NUMBER_ASC) {
          this.selectedSort = SortTypeCRROTW.PHONE_NUMBER_DESC
        } else if (this.selectedSort === SortTypeCRROTW.PHONE_NUMBER_DESC) {
          this.selectedSort = null
        } else {
          this.selectedSort = SortTypeCRROTW.PHONE_NUMBER_ASC
        }
        break

      case SortFieldsCRROTW.COURIER_NAME:
        if (this.selectedSort === SortTypeCRROTW.COURIER_NAME_ASC) {
          this.selectedSort = SortTypeCRROTW.COURIER_NAME_DESC
        } else if (this.selectedSort === SortTypeCRROTW.COURIER_NAME_DESC) {
          this.selectedSort = null
        } else {
          this.selectedSort = SortTypeCRROTW.COURIER_NAME_ASC
        }
        break

      case SortFieldsCRROTW.PICKUP_DURATION_CRROTW:
        if (this.selectedSort === SortTypeCRROTW.PICKUP_DURATION_CRROTW_ASC) {
          this.selectedSort = SortTypeCRROTW.PICKUP_DURATION_CRROTW_DESC
        } else if (
          this.selectedSort === SortTypeCRROTW.PICKUP_DURATION_CRROTW_DESC
        ) {
          this.selectedSort = null
        } else {
          this.selectedSort = SortTypeCRROTW.PICKUP_DURATION_CRROTW_ASC
        }
        break

      case SortFieldsCRROTW.LAST_UPDATED:
        if (this.selectedSort === SortTypeCRROTW.LAST_UPDATED_ASC) {
          this.selectedSort = SortTypeCRROTW.LAST_UPDATED_DESC
        } else if (this.selectedSort === SortTypeCRROTW.LAST_UPDATED_DESC) {
          this.selectedSort = null
        } else {
          this.selectedSort = SortTypeCRROTW.LAST_UPDATED_ASC
        }
        break

      case SortFieldsCRROTW.PICKUP_DURATION_SHPCRT:
        if (this.selectedSort === SortTypeCRROTW.PICKUP_DURATION_SHPCRT_ASC) {
          this.selectedSort = SortTypeCRROTW.PICKUP_DURATION_SHPCRT_DESC
        } else if (
          this.selectedSort === SortTypeCRROTW.PICKUP_DURATION_SHPCRT_DESC
        ) {
          this.selectedSort = null
        } else {
          this.selectedSort = SortTypeCRROTW.PICKUP_DURATION_SHPCRT_ASC
        }
        break

      default:
        if (
          this.selectedSort === SortTypeCRROTW.PICKUP_DURATION_FIRST_CRRSRC_ASC
        ) {
          this.selectedSort = SortTypeCRROTW.PICKUP_DURATION_FIRST_CRRSRC_DESC
        } else if (
          this.selectedSort === SortTypeCRROTW.PICKUP_DURATION_FIRST_CRRSRC_DESC
        ) {
          this.selectedSort = null
        } else {
          this.selectedSort = SortTypeCRROTW.PICKUP_DURATION_FIRST_CRRSRC_ASC
        }
        break
    }

    this.fetchPickups(true)
  }

  public onSearch = Utils.debounce((search: string): void => {
    if (search.length > 2 || search.length === 0) {
      this.fetchPickups(true)
    }
  }, 500)

  public onClickCheckBoxContactCourier = async (
    index: number
  ): Promise<void> => {
    if (this.controller.isLoading) return
    if (this.controller.pickupCRROTWData.data?.length) {
      const status = this.controller.pickupCRROTWData.data[index].isContacted
      const taskId = this.controller.pickupCRROTWData.data[index].taskId
      await controller.updateIsContactedCRROTW({
        taskId: taskId,
        status: status ? 1 : 2,
        index: index,
      })
    }
  }

  public onForceCRRCNC(shipmentId: string): void {
    this.forceCRRCNCShipmentId = shipmentId
    this.showForceCRRCNCModal = true
  }

  public onCancelForceCRRCNC(): void {
    this.showForceCRRCNCModal = false
    this.forceCRRCNCShipmentId = ''
  }

  private headerCellMapper(
    title: string | number,
    colWidth: string
  ): IHeaderCell {
    return {
      title: title,
      customStyle: {
        minWidth: colWidth,
        maxWidth: colWidth,
      },
    }
  }

  private tableCellMapper(
    value:
      | string
      | number
      | Date
      | string[]
      | { [key: string]: string | number | boolean },
    colWidth: string
  ): ITableCell {
    return {
      value,
      customStyle: {
        maxWidth: colWidth,
        minWidth: colWidth,
      },
    }
  }

  public handleExportPickupList(): void {
    const params = {
      file_name: `${this.statusId}-${Utils.formatDateWithIDLocale(
        new Date().toISOString(),
        'YYYYMMDDHHmss'
      )}`,
      date_from: Utils.formatDate(dayjs().subtract(1, 'week'), 'YYYY-MM-DD'),
      keyword: this.parameters.search || undefined,
      shipmentType: <string>(
        this.parameters.shipmentType
          ?.map(
            type =>
              this.shipmentTypeOptions.find(option => option.label === type)
                ?.value
          )
          .join(',')
      ),
      pickupDuration: encodeURIComponent(
        <string>this.parameters.pickupDuration?.value
      ),
      courierCall: <string | undefined>this.parameters.courierCall?.value,
    }

    if (!this.parameters.search) {
      delete params.keyword
    }

    controller.exportCRROTW(<Record<string, string | number | boolean>>params)
  }

  public syncData(): void {
    this.fetchPickups(true, true)
  }

  private getMinutes(time: string): number {
    const minutes = (new Date().getTime() - new Date(time).getTime()) / 60000
    return Number(minutes.toFixed())
  }

  private getDuration(time: string): string {
    const duration = dayjs.duration(dayjs().diff(dayjs(time)))

    const result = []

    //Get Days
    const days = Math.floor(duration.asDays())
    if (days) {
      result.push(`${days}d`)
    }

    //Get Hours
    const hours = duration.hours()
    if (hours) {
      result.push(`${hours}h`)
    }

    //Get Minutes
    const minutes = duration.minutes()
    result.push(`${minutes}m`)

    return result.join(', ')
  }

  public getOverSLA(data: string): boolean {
    return dayjs().diff(dayjs(data), 'hours') >= 48
  }

  private isCanBeCanceled(shipmentId: string): boolean {
    return (
      shipmentId.substr(0, 2) === 'AG' ||
      shipmentId.substr(0, 3) === 'GAG' ||
      shipmentId.substr(0, 4) === 'GARA' ||
      shipmentId.substr(0, 3) === 'ARA' ||
      shipmentId.substr(0, 2) === 'C1'
    )
  }

  @Watch('controller.pickupCRROTWData', { deep: true })
  private onPickupCRROTWDataChange(pickup: PickupCRROTWs): void {
    this.tableData = <ITableCell[][]>pickup.data?.map((data, index) => {
      return [
        this.tableCellMapper(
          index +
            1 +
            this.parameters.perPage * (this.parameters.page - 1) +
            '.',
          '60px'
        ),
        this.tableCellMapper([data.shipmentId, data.bookingId], '160px'),
        this.tableCellMapper(<string>data.pickup?.fullname, '200px'),
        this.tableCellMapper(<string>data.pickup?.phoneNumber, '190px'),
        this.tableCellMapper(
          [data.flag, data.createdAt, <string>data.productType],
          '140px'
        ),
        this.tableCellMapper(
          <string[]>[
            data.pickup?.latitude,
            data.pickup?.longitude,
            data.pickup?.address,
          ],
          '260px'
        ),
        this.tableCellMapper(
          [
            `[${data.courierPickupDetail?.courierId}] ${data.courierPickupDetail?.courierName}`,
            `(${data.courierPickupDetail?.courierTypeValid}) ${data.courierPickupDetail?.phoneNumber}`,
            data.shipmentId,
            <string>data.courierPickupDetail?.phoneNumber,
          ],
          '200px'
        ),
        this.tableCellMapper(
          {
            minutes: this.getMinutes(data.crrotwAt),
            canContact: data.canContact,
            isContacted: data.isContacted,
            index: index,
          },
          '200px'
        ),
        this.tableCellMapper(
          [
            Utils.formatDateWithIDLocale(data.lastUpdated, 'DD MMM YYYY'),
            Utils.formatTimeZone(
              Utils.formatDateWithIDLocale(data.lastUpdated, 'HH:mm Z')
            ),
          ],
          '176px'
        ),
        this.tableCellMapper(this.getDuration(data.createdAt), '200px'),
        this.tableCellMapper(this.getDuration(data.crrsrcAt), '200px'),
        this.tableCellMapper(
          {
            shipmentId: data.shipmentId,
            courierId: String(data.courierPickupDetail?.courierId),
            isDedicated: String(data.isDedicated),
            isCanBeCanceled: this.isCanBeCanceled(data.shipmentId)
          },
          '183px'
        ),
      ]
    })
    this.pagination = <Pagination>pickup.pagination
    this.summary = <PickupCRROTWSummary>pickup.summary
  }

  @Watch('controller.isSuccessForceCRRCNC')
  onForceCRRCNCSuccessChange(data: boolean): void {
    if (data) {
      this.showForceCRRCNCModal = false
      this.showSuccessForceCRRCNCModal = true
      this.forceCRRCNCShipmentId = ''

      this.fetchPickups(true)
      this.controller.setIsSuccessForceCRRCNC(false)
    }
  }

  @Watch('controller.isFailedForceCRRCNC')
  onForceCRRCNCFailedChange(data: boolean): void {
    if (data) {
      this.showForceCRRCNCModal = true

      this.controller.setIsFailedForceCRRCNC(false)
    }
  }
}
